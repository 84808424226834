import { Col, ConfigProvider, Row } from "antd";
import Table from "antd/es/table";
import React, { useEffect, useState } from "react";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { FetchAllTransactions } from "../api/ApiCalls/Transactions";
import { AllTransactionsResponse } from "../types/DataTypes";
import moment from "moment";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type ApiResponse = AllTransactionsResponse[];

const Transactions = () => {
  const [transactions, setTransactions] = useState<ApiResponse>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isCopied, setCopied] = useState(false);
  const { md } = useBreakpoint();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const fetchAllTransactions = async () => {
    try {
      const res = await FetchAllTransactions();
      setTransactions(res);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "transparent",
            colorBgContainer: "transparent",
          },
          Pagination: {
            itemActiveBg: "#3AA1FF",
            colorPrimary: "white",
            borderRadius: 12,
            itemSize: 40,
          },
        },
      }}
    >
      <Row
        style={{
          width: "100%",
          maxHeight: "100%",
          borderRadius: "24px",
          border: "2px solid #fff",
          padding: "10px",
          background: "rgba(246, 247, 251, 0.80)",
          backdropFilter: "blur(35px)",
          boxShadow: "0px 3px 12px 0px rgba(0, 0, 0, 0.05)",
          overflow: "auto",
        }}
      >
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={[
              {
                title: "Wallet Address",
                dataIndex: "user_wallet_address",
                key: "user_wallet_address",
                render: (text: string) => {
                  if (text) {
                    return (
                      <CopyToClipboard text={text} onCopy={() => handleCopy()}>
                        <span style={{ cursor: "pointer" }}>
                          <CopyOutlined style={{ marginRight: "8px" }} />
                          {text.slice(0, 6)}...{text.slice(-4)} {/* Display the formatted text */}
                        </span>
                      </CopyToClipboard>
                    );
                  } else {
                    
                    return <span>N/A</span>
                  }
                },
              },
              {
                title: "Transaction Hash",
                dataIndex: "transaction_hash",
                key: "transaction_hash",
                render: (text: string) => {
                  if (text) {
                    return (
                      <CopyToClipboard text={text} onCopy={() => handleCopy()}>
                        <span style={{ cursor: "pointer" }}>
                          <CopyOutlined style={{ marginRight: "8px" }} />
                          {text.slice(0, 6)}...{text.slice(-4)} {/* Display the formatted text */}
                        </span>
                      </CopyToClipboard>
                    );
                  } else {
                    
                    return <span>N/A</span>
                  }
                },
              },
              {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
              },
              {
                title: "From To",
                dataIndex: "from_to",
                key: "from_to",
  
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
              },
              {
                title: "Created At",
                dataIndex: "created_at",
                key: "created_at",
              },
            ]}
            dataSource={transactions.map((transaction, index) => ({
              ...transaction,
              key: index.toString(),
            }))}
          />
        </Col>
      </Row>
      {isCopied && (
        <div
          style={{
            marginLeft: "8px",
            color: "green",
            position: "absolute",
            left: "50%",
            top: "10%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          Copied to clipboard!
        </div>
      )}
    </ConfigProvider>
  );
};

export default Transactions;
