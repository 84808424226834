import { Col, ConfigProvider, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { AllUsersAPI } from "../api/ApiCalls";
import { AllUserResponse } from "../types/DataTypes";
import moment from "moment";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

type ApiResponse = AllUserResponse[];

const Users = () => {
  const [AllUser, setAllusers] = useState<ApiResponse>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isCopied, setCopied] = useState(false);
  const { md, sm } = useBreakpoint();
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const fetchAllUsers = async () => {
    try {
      const res = await AllUsersAPI();
      setAllusers(res);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "transparent",
            colorBgContainer: "transparent",
          },
          Pagination: {
            itemActiveBg: "#3AA1FF",
            colorPrimary: "white",
            borderRadius: 12,
            itemSize: 40,
          },
        },
      }}
    >
      <Row
        style={{
          width: "100%",
          maxHeight: "100%",
          borderRadius: "24px",
          border: "2px solid #fff",
          padding: "10px",
          background: "rgba(246, 247, 251, 0.80)",
          backdropFilter: "blur(35px)",
          boxShadow: "0px 3px 12px 0px rgba(0, 0, 0, 0.05)",
          overflow: "auto",
        }}
      >
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={[
              {
                title: "Wallet address",
                dataIndex: "wallet_address",
                render: (text: string, record: AllUserResponse) => (
                  <>
                    <CopyToClipboard text={text} onCopy={() => handleCopy()}>
                      <CopyOutlined
                        style={{
                          marginLeft: "8px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                      />
                    </CopyToClipboard>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {text.slice(0, 5)}....{text.slice(-6)}
                    </span>
                  </>
                ),
              },
              {
                title: "Last login",
                dataIndex: "last_login",
                render: (text: string) => (
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {md
                      ? moment(text).format("DD-MM-YYYY    h:mm A")
                      : moment(text).format("DD-MM-YYYY")}
                  </span>
                ),
                responsive: ["sm"],
              },
              {
                title: "Created at",
                dataIndex: "date_joined",
                render: (text: string) => (
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {" "}
                    {md
                      ? moment(text).format("DD-MM-YYYY    h:mm A")
                      : moment(text).format("DD-MM-YYYY")}
                  </span>
                ),
                responsive: ["sm"],
              },
            ]}
            dataSource={AllUser.map((user, index) => ({
              ...user,
              key: index.toString(),
            }))}
            expandable={
              !sm
                ? {
                    expandedRowRender: (record: AllUserResponse) => (
                      <div className="expandable-dates-div">
                        <div>
                          <p>Last Login</p>
                          <h6>
                            {moment(record.last_login).format("DD-MM-YYYY")}
                          </h6>
                        </div>
                        <div>
                          <p>Created at</p>
                          <h6>
                            {moment(record.date_joined).format("DD-MM-YYYY")}
                          </h6>
                        </div>
                      </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <UpOutlined onClick={(e) => onExpand(record, e)} />
                      ) : (
                        <DownOutlined onClick={(e) => onExpand(record, e)} />
                      ),
                    expandIconColumnIndex: 3,
                  }
                : undefined
            }
          />
        </Col>
      </Row>
      {isCopied && (
        <div
          style={{
            marginLeft: "8px",
            color: "green",
            position: "absolute",
            left: "50%",
            top: "10%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "100%",
          }}
        >
          Copied to clipboard!
        </div>
      )}
    </ConfigProvider>
  );
};

export default Users;
