import axios from "../axios";
import { AllTransactionsResponse, AllUserResponse } from "../../types/DataTypes";

type ApiResponse = AllTransactionsResponse[];

export const FetchAllTransactions = async (): Promise<ApiResponse> => {

  try {
    const endPoint = "admin/transactions/";
    const res = await axios.get(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
