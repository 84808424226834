import React from "react";
import background from "../assets/svg/Backgrounds.svg";
import amino from "../assets/svg/Amino.svg";
import dash from "../assets/svg/Dashboard.svg";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Layout,
  Row,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import { LoginAPI } from "../api/ApiCalls";
import { login } from "../redux/reducers";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const Login = () => {
  const dispatch = useDispatch();
  const { xl, md } = useBreakpoint();
  const [messageApi, contextHolder] = message.useMessage();

  const error = (errMessage: string) => {
    messageApi.open({
      type: "error",
      content: errMessage,
    });
  };

  const test = async (values: any) => {
    try {
      const res = await LoginAPI({
        username: values.username,
        password: values.password,
      });
      if (res) {
        const payload = {
          user: "Dummy",
          isAuthenticated: true,
          token: res.access,
          refreshToken: null,
        };
        dispatch(login(payload));
      }
    } catch (err: any) {
      const errMessage = err.response.data.detail;
      error(errMessage);
    }
  };
  const onFinish = (values: any) => {
    test(values);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#4C40F7",
            borderRadius: 32,
            paddingInline: 16,
            fontSize: 20,
            controlHeight: xl ? 64 : md ? 55 : 45,
            fontWeight: 600,
          },
        },
      }}
    >
      <Layout>
        {contextHolder}
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #DEDEDE",
              borderRadius: "34px",
              background: "rgba(246, 247, 251, 0.50)",
              backdropFilter: "blur(35px)",
              gap: "22px",
            }}
            className="Login-form"
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                borderBottom: "1px solid #DEDEDE",
                paddingBottom: "12px",
                width: "100%",
              }}
            >
              <img src={amino} className="img-amino" />
              <img src={dash} className="img-dash" />
            </div>
            <p
              style={{
                color: "rgba(0, 0, 0, 0.45)",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
                lineHeight: "18px",
                maxWidth: "260px",
              }}
            >
              Secure Access to Your Admin Wallet Dashboard
            </p>

            <Form
              name="normal_login"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              style={{ width: "100%" }}
            >
              <Form.Item name="username" style={{ marginBottom: "10px" }}>
                <Input required placeholder="Email" />
              </Form.Item>

              <Form.Item name="password" style={{ marginBottom: "22px" }}>
                <Input.Password
                  required
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ marginBottom: "22px" }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout>
    </ConfigProvider>
  );
};

export default Login;
