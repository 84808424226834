import axios from "../axios";
import { LoginResponse } from "../../types/DataTypes";

export const LoginAPI = async (payload: any): Promise<LoginResponse> => {
  console.log("Login API call!");

  try {
    const endPoint = "token/";
    const res = await axios.post(endPoint, payload);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
