import axios from "../axios";
import { AllUserResponse } from "../../types/DataTypes";

type ApiResponse = AllUserResponse[];

export const AllUsersAPI = async (): Promise<ApiResponse> => {
  try {
    const endPoint = "admin/users/";
    const res = await axios.get(endPoint);
    if (!res?.data) throw "Something went wrong";
    console.log("All users API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while AllUsers API>>>", err.response.data);
    return Promise.reject(err);
  }
};
