import { ConfigProvider } from "antd";
import React, { useState } from "react";
import App from "./App";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import light from "./tokens/light.json";

const ThemedApp = () => {
  const [token, setToken] = useState(light);
  const { md } = useBreakpoint();

  //<ConfigProvider theme={{ token }}>
  //  </ConfigProvider>

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            paddingBlock: md ? 10 : 8,
            borderRadius: 21,
            paddingInline: 15,
          },
          Form: {
            itemMarginBottom: 0,
          },
          Dropdown: {
            paddingXXS: 12,
            controlPaddingHorizontal: 80,
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  );
};

export default ThemedApp;
