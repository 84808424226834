import React from "react";
import {
  Layout,
  Row,
  theme,
  Col,
  Menu,
  ConfigProvider,
  Button,
  MenuProps,
  Dropdown,
} from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import img from "../assets/svg/Header Top.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useDispatch } from "react-redux";
import { logout } from "../redux/reducers";
import { useLocation, useNavigate } from "react-router-dom";

const TopHeader = () => {
  const { sm } = useBreakpoint();
  return (
    <Row
      style={{ padding: sm ? "0 30px" : "0 16px", backgroundColor: "#FFFFFF" }}
    >
      <Row
        style={{
          padding: "20px 0",
          gap: "20px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Col>
          <img
            src={img}
            style={{ height: sm ? "34px" : "30px", marginTop: "8px" }}
          />
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <Row style={{ width: "100%", alignItems: "center" }}>
            <Col span={18}>{sm && <HorizontalMenu />}</Col>
            <Col
              span={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <HeaderButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default TopHeader;

const HorizontalMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { key: "/", label: "Users", path: "/" },
    { key: "/transactions", label: "Transactions", path: "/transactions" },
  ];

  const onClick = (e: any) => {
    navigate(e.key);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBg: "transparent",
            colorSplit: "transparent",
          },
        },
      }}
    >
      <Menu
        mode="horizontal"
        items={menuItems.map((item) => ({ ...item, onClick }))}
        selectedKeys={[location.pathname]}
      />
    </ConfigProvider>
  );
};

const HeaderButton = () => {
  const { sm } = useBreakpoint();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();


  let items: MenuProps["items"] = [
    {
      label: "Help",
      key: "0",
    },
    {
      label: "Logout",
      key: "1",
      icon: <LogoutOutlined />,
      onClick: () => {
        dispatch(logout());
      },
    },
  ];

  if (!sm) {
    items = [
      {
        label: "Users",
        key: "/",
        onClick: () => navigate('/'),
      },
      {
        label: "Transactions",
        key: "/transactions",
        onClick: () => navigate('/transactions'),
      },
      ...items,
    ];
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              borderRadius: 19,
              colorPrimary: "#3AA1FF",
              paddingInline: 16,
              paddingInlineSM: 16,
              paddingInlineLG: 16,
              padding: 16,
            },
          },
        }}
      >
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            type="primary"
            icon={<UserOutlined />}
            onClick={(e: any) => {
              e.preventDefault();
            }}
          >
            {sm && "Your Account"}
          </Button>
        </Dropdown>
      </ConfigProvider>
    </>
  );
};
