import { Layout, Row, theme } from "antd";
import "./App.scss";
import { useSelector } from "react-redux";
import { userState } from "./redux/reducers/userSlice";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useEffect } from "react";
import TopHeader from "./components/TopHeader";
import Users from "./pages/Users";
import background from "../src/assets/svg/Backgrounds.svg";
import Login from "./pages/Login";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Transactions from "./pages/Transactions";

const { Content } = Layout;
const { useToken } = theme;

const App = () => {
  const { sm } = useBreakpoint();
  const { token } = useToken();
  const user = useSelector(userState);

  useEffect(() => {
    console.log(">>", user);
  }, []);
  return (
    <Router>
      <Layout style={{ minHeight: "100dvh" }}>
        {user.isAuthenticated ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
        {/* {false ? <LoggedInRoutes /> : <NotLoggedInRoutes />} */}
      </Layout>
    </Router>
  );
};

export default App;

const LoggedInRoutes = () => {
  const { sm } = useBreakpoint();
  return (
    <Layout style={{ height: "100dvh", zIndex: 2 }}>
      <TopHeader />
      <Content
        style={{
          height: "100dvh",
          overflow: "auto",
          padding: sm ? "30px" : "16px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            zIndex: -1,
          }}
        />

        <Routes>
          <Route path="/" element={<Users />} />
          <Route path="/transactions" element={<Transactions />} />
        </Routes>
      </Content>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </Layout>
  );
};
